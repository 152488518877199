import React from 'react';
import { styles } from '../../styles';
import { Carrier } from '../../types';
import CarrierItem from './carrier-item';

interface Props {
  carriers: Carrier[];
  chooseCarrier: (carrier: Carrier) => void;
  featureFlags: string[];
  id: string;
}

export default function CarrierList(props: Props) {
  const {
    id,
    carriers,
    featureFlags,
    chooseCarrier,
  } = props;

  const isFeatureFlagged = (featureFlagType: string) => (featureFlags.includes(featureFlagType));
  const shouldShowCarrierInCompatibleSection = ({ featureFlagRequired }: Carrier) => (
    !featureFlagRequired || isFeatureFlagged(featureFlagRequired)
  );
  const shouldShowCarrierInOutOfNetworkSection = ({ featureFlagExcludes }: Carrier) => (
    !featureFlagExcludes || !isFeatureFlagged(featureFlagExcludes)
  );
  const compatibleAndOtherCarriers = carriers.filter(
    (
      carrier: Carrier,
    ) => shouldShowCarrierInCompatibleSection(carrier)
        && shouldShowCarrierInOutOfNetworkSection(carrier));

  return (
    <ul
      {...styles.carrierList}
      data-browser-test-list={id}
    >
      {compatibleAndOtherCarriers.map((carrier) => (
        <CarrierItem
          key={carrier.name}
          carrier={carrier}
          onClick={chooseCarrier}
          styles={styles}
        />
      ))}
    </ul>
  );
}
