/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/sort-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, { useState } from 'react';
import _ from 'lodash';
import { render } from 'react-dom';
import queryString from 'query-string';
import { css } from 'glamor';

import { styles } from './styles';
import { carrierLogoUrl, formatBenefits, normalizeDate } from './util';
import { INITIAL_STATE } from './state/index';

import { inNetworkCarriers, outOfNetworkCarriers, CARRIERS } from './constants';
import { BenefitsMatrix } from './benefits-matrix';
import SpinningLoader from './spinning-loader';
import { BenefitsSummary } from './benefits-summary';
import CarrierList from './components/carrier-list';

// Apply Global Styles
css.global('html, body', {
  background: 'rgb(248, 248, 248)',
  margin: '0',
  padding: '0',
  minWidth: '320px',
});

css.global('button', {
  fontFamily: '"proxima-nova", "Helvetica", "Verdana", sans-serif',
  outline: 'none',
});

css.global('h4, h2, h1', {
  fontWeight: '400',
  margin: '0 0 1rem 0',
});

css.global('h1', {
  fontSize: '1.5rem',
});

css.global('h2', {
  fontSize: '1rem',
  fontWeight: '600',
  textTransform: 'uppercase',
  letterSpacing: '0.1rem',
});

css.global('h3', {
  fontSize: '16pt',
  height: '24px',
  margin: '20px 0',
});

css.global('a', {
  textDecoration: 'none',
});

function Header({ goBack, userData }) {
  return (
    <div
      {...styles.header}
      {...(userData?.environment?.is_training ? styles.trainingHeader : {})}
      onClick={goBack}
    >
      <div {...styles.headerLink}>
        <span {...styles.backBtn} />
        <span {...styles.headerText}>Insurance Benefits Check</span>
      </div>
    </div>
  );
}

const goToLogin = () => {
  // NB: window.location.search contains a leading '?'
  window.location.href = `https://${
    process.env.HELIOS_RETAIL_DOMAIN
  }/login?next=${encodeURIComponent(window.location.href)}`;
};

const patientDataForTransport = (patientData) => {
  const { _id_field, ...toSend } = patientData;
  const [subscriber_id, member_id] = (_id_field || '').split('-');
  return {
    ...toSend,
    subscriber_dob: normalizeDate(toSend.subscriber_dob),
    subscriber_id,
    member_id,
  };
};

const qs = () => queryString.parse(window.location.search);

const inLoading = (state) => state.loading || !state.jwt || state.userData.features === null;
const hasBenefits = (state) => state.benefits && state.benefits.is_success;
const unableToRetrieveBenefits = (state) => {
  const examBenefits = state.benefits?.benefit_exams?.length;
  const frameBenefits = state.benefits?.benefit_frames?.length;
  const contactLensesBenefits = state.benefits?.benefit_contact_lenses?.length;
  const contactsFittingBenefits = state.benefits?.benefit_contact_fittings?.length;
  const contactsLensesAndFittingBenefits = state.benefits?.benefit_contact_lenses_and_fittings?.length;
  const planBenefits = state.benefits?.benefit_plans?.length;
  const hasNoBenefitsReturned = !examBenefits
      && !frameBenefits
      && !contactLensesBenefits
      && !contactsFittingBenefits
      && !contactsLensesAndFittingBenefits
      && !planBenefits;

  if (state.benefits && state.benefits.is_success && hasNoBenefitsReturned) {
    return true;
  }

  return false;
};

// for UHC/Davis eligibility forms
const handleFormSubmit = ({ handleSubmit, setBenefitCheckLoading, patientData }) => {
  setBenefitCheckLoading(true);

  // since animation is done purely with CSS, set a time out for 2.5 seconds
  // to render it to the page for user confirmation on form submission instead
  // of no action
  setTimeout(() => {
    handleSubmit({ patientData, setBenefitCheckLoading });
  }, 2500);
};

function CarrierContainer({ chooseCarrier, skip, showOON, featureFlags }) {
  return (
    <div>
      <p {...styles.carrierListHeader}>Compatible insurance carriers</p>
      <CarrierList
        carriers={inNetworkCarriers}
        chooseCarrier={chooseCarrier}
        featureFlags={featureFlags}
        id="compatible-carriers-list"
      />
      <p {...styles.carrierListHeader} className="outOfNetwork">Other insurance carriers</p>
      <CarrierList
        carriers={outOfNetworkCarriers}
        chooseCarrier={chooseCarrier}
        featureFlags={featureFlags}
        id="other-carriers-list"
      />
      {showOON ? (
        <p {...styles.carrierListFooter}>
          <button {...styles.textBtn} onClick={skip}>
            Skip this
          </button>
        </p>
      ) : null}
    </div>
  );
}

function OutOfNetworkPrompt({
  carrier,
  setReimbursementFollowUpInterest,
  resetSelectedCarrier,
}) {
  // to discern which action is selected and update radio button checked UI
  const [reimbursementAction, setReimbursementAction] = useState({
    sendReimbursement: true,
    doNotSendReimbursement: false,
  });

  // to disable the form submit button on initial submit
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    setReimbursementFollowUpInterest(reimbursementAction.sendReimbursement, carrier.carrierKey);
  };

  return (
    <>
      <div {...styles.carrierPillContainer}>
        <div
          {...styles.carrierPill}
          onClick={() => resetSelectedCarrier({ company: false })}
        >
          {carrier.carrierKey === 'other' ? (
            <p {...styles.otherCarrier}>{carrier.name}</p>
          ) : (
            <img
              {...styles.carrierLogo}
              {...styles.logoPadding}
              alt={carrier.name}
              src={carrierLogoUrl(carrier.logo)}
            />
          )}
          <button {...styles.editButton} type="button">Edit</button>
        </div>
      </div>
      <div {...styles.outOfNetworkContainer}>
        <p {...styles.outOfNetworkHeader}>Out-of-network provider</p>
        <p {...styles.outOfNetworkMessage} {...styles.centeredBlock}>
          This provider is currently out-of-network with Warby Parker.
        </p>
        <p {...styles.outOfNetworkMessage} {...styles.centeredBlock} className="oon-last-para">
          {'Don’t have FSA or HSA? Applying for reimbursement if you\
          have an out-of-network benefit included in your vision insurance plan is simple!'}
        </p>
        <form onSubmit={handleSubmit}>
          <div {...styles.outOfNetworkRadioButtonContainer}>
            <label
              htmlFor="sendReimbursement"
              {...styles.outOfNetworkLabel}
              className={reimbursementAction.sendReimbursement ? 'checked' : null}
            >
              <input
                {...styles.outOfNetworkRadioButton}
                id="sendReimbursement"
                name="reimbursement"
                onChange={() => {
                  setReimbursementAction({
                    sendReimbursement: true,
                    doNotSendReimbursement: false,
                  });
                }}
                type="radio"
                value="sendReimbursement"
              />
            </label>
            <p {...styles.outOfNetworkText}>Send reimbursement instructions after checkout</p>
          </div>
          <div {...styles.outOfNetworkRadioButtonContainer}>
            <label
              htmlFor="doNotSendReimbursement"
              {...styles.outOfNetworkLabel}
              className={reimbursementAction.doNotSendReimbursement ? 'checked' : null}
            >
              <input
                {...styles.outOfNetworkRadioButton}
                id="doNotSendReimbursement"
                name="reimbursement"
                onChange={() => {
                  setReimbursementAction({
                    sendReimbursement: false,
                    doNotSendReimbursement: true,
                  });
                }}
                type="radio"
                value="doNotSendReimbursement"
              />
            </label>
            <p {...styles.outOfNetworkText}>
              Don&apos;t send reimbursement instructions after checkout
            </p>
          </div>
          <div {...styles.outOfNetworkButtonContainer}>
            <button
              {...styles.primaryButton}
              {...styles.outOfNetworkButton}
              disabled={isFormSubmitted}
              type="submit"
            >
              Proceed to checkout
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

class StateManager extends React.Component {
  constructor(props) {
    super(props);

    // Read in parameters sent from POE
    // carrier_key : key for deteremining which downstream API to look up insurance eligibility
    // insurance company is being used with a given carrier. ex. 'davis' | 'united'
    const carrierKey = qs().carrier_key;
    // carrier_id : currently helios APIs do not give us enough information around which
    // insurance provider is being used along side an insurance carrier. Our Sales Advisors
    // are more concerned about asking customers for their insurance provider.
    // I might be getting some of these terms incorrect. But this is the
    // language used in Helios/WWW. ex. 'fep', 'united', 'spectera'
    const carrierId = qs().carrier_id;

    const carrier = _.find(CARRIERS, {
      carrierKey,
      ...(carrierId && { id: carrierId }),
    });

    this.state = {
      ...this.props.initialState,
      carrier,
      company: null,
      error: null,
      estimateId: qs().estimate_id,
      estimateIsNew: qs().estimate_id ? false : null,
      redirectToPoeVisit: qs().redirect_to_poe_visit,
      selected: !!(qs().selected),
      visitId: qs().visit_id,
    };

    this.loadJWT()
      .then(() => {
        this.loadUserData()
          .then(() => {
            if (this.state.estimateId) {
              this.fetchInsuranceBenefits();

              // Populate first & last from estimate if possible
              this.apiCall(`/api/v1/estimates/${this.state.estimateId}`)
                .then((resp) => {
                  this.setState({
                    patientData: {
                      ...this.state.patientData,
                      first_name: this.state.patientData.first_name || resp.first_name || '',
                      last_name: this.state.patientData.last_name || resp.last_name || '',
                    },
                  });
                })
                .catch((err) => {
                  const message = `Error fetching patient data for estimate_id: ${this.state.estimateId}`;
                  logger.error({ err }, message);
                });
            }
          });
      });
  }

  loadJWT() {
    // TODO: pull XSRF & use POST
    return fetch(`https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/user/jwt`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => {
        if (!resp.ok) {
          goToLogin();
          return null;
        }
        return resp.json();
      })
      .then((resp) => {
        this.setState({ jwt: resp.token });
      })
      .catch((err) => {
        const message = 'Error fetching JWT for insurance eligibility';
        logger.error({ err }, message);
      });
  }

  apiCall(url, { method = 'GET', data = null } = {}) {
    return fetch(`https://${process.env.HELIOS_RETAIL_DOMAIN}${url}`, {
      headers: { Authorization: `bearer ${this.state.jwt}` },
      method,
      body: data ? JSON.stringify(data) : null,
    })
      .then((resp) => {
        if (resp.status === 403) {
          goToLogin();
          return null;
        }
        if (!resp.ok) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ status: resp.status });
        }
        return resp.json();
      });
  }

  loadUserData() {
    return this.apiCall('/api/v1/user/me', {})
      .then((resp) => {
        this.setState({ userData: resp });
      })
      .catch((err) => {
        const message = 'Error fetching user data';
        logger.error({ err }, message);
      });
  }

  fetchInsuranceBenefits() {
    this.setState({ loading: true });
    return this.apiCall(`/api/v1/estimates/${this.state.estimateId}/insurance-benefits`)
      .then((resp) => {
        this.setState({
          loading: false,
          carrier: resp.carrier_key
            ? _.find(CARRIERS, {
              carrierKey: resp.carrier_key,
              primary: true,
            }) : this.state.carrier,
          benefits: formatBenefits(resp) || this.state.benefits,
        }, () => {
        });
      })
      .catch((err) => {
        const message = `Error fetching carrier benefits for estimate_id: ${this.state.estimateId}`;
        logger.error({ err }, message);
      });
  }

  handlePatientDataUpdate = (field, value) => {
    this.setState({
      patientData: {
        ...this.state.patientData,
        [field]: value,
      },
    });
  };

  estimateCoverageForItems(frame_benefit_id, items) {
    const { benefits: { benefit_eligibility_id } } = this.state;
    const data = {
      items,
      benefit_eligibility_id,
    };

    return this.apiCall('/api/v2/insurance/coverage', {
      method: 'POST',
      data,
    })
      .catch((err) => {
        const benefitEligibilityId = `benefit_eligibility_id: ${benefit_eligibility_id}`;
        const message = `Error fetching coverage for items with ${benefitEligibilityId}`;
        logger.error({ err }, message);
      });
  }

  updateBenefitsMatrix = (items) => {
    const hasNoBenefitsReturned = unableToRetrieveBenefits(this.state);

    return !hasNoBenefitsReturned && (
      this.estimateCoverageForItems(
        _.head(this.state.benefits.benefit_frames).frame_benefit_id,
        items,
      )
        .then((resp) => {
          this.setState({
            itemsWithBenefits: _.map(resp.items_with_benefits, (benefit) => _.assign(
              {},
              _.find(items, (i) => String(i.estimate_item_id) === String(benefit.estimate_item_id)),
              benefit,
            ),
            ),
          });
        })
    );
  };

  handleEligibilitySubmit = ({ patientData, setBenefitCheckLoading }) => {
    // clear out any stale errors from previous api call to get
    // patient insurance benefits
    this.setState({
      error: null,
    });

    this.apiCall('/api/v1/insurance/eligibility', {
      method: 'POST',
      data: {
        carrier_key: this.state.carrier.carrierKey,
        patient_data: patientDataForTransport(patientData),
      },
    })
      .then((resp) => {
        // resets animated loader to default button state
        // after API returns response
        setBenefitCheckLoading(false);

        const hasNoBenefitsReturned = unableToRetrieveBenefits(this.state);
        if (hasNoBenefitsReturned) {
          // if API call is successful (with 200 status) but there is a failure in
          // contacting provider and no benefits are returned
          this.setState({
            benefits: resp,
            loading: false,
          }, () => null);
        }

        this.setState({
          benefits: resp,
          loading: false,
        });
      })
      .catch((err) => {
        // resets animated loader to default button state
        // after API returns response
        setBenefitCheckLoading(false);

        const message = `Error checking eligibility for carrier: ${this.state.carrier.carrierKey}`;
        this.setState({
          loading: false,
          error: message,
        });
        logger.error({ err }, message);
      });
    this.setState({ loading: true });
  };

  clearEligibilityData() {
    this.setState({
      benefits: null,
      patientData: INITIAL_STATE.patientData,
    });
  }

  resetSelectedCarrier = ({ company = false, benefitSummary = false }) => {
    this.setState({ carrier: null }, () => {
      this.clearEligibilityData();

      if (company) {
        this.setState({
          company: null,
        });
      }
    });

    if (benefitSummary && this.state.estimateId) {
      // if edit button is selected on insurance benefits page after
      // benefits have already been applied to estimate, apply empty
      // eligibility data to remove it from estimate
      this.applyToEstimate({ shouldExit: false });
    }
  };

  resetSelectedCompany = ({ benefitSummary = false }) => {
    // resets only company and form data
    // but leaves carrier in local state
    this.setState({
      company: null,
    }, () => {
      this.clearEligibilityData();

      if (benefitSummary && this.state.estimateId) {
        // if edit button is selected on insurance benefits page after
        // benefits have already been applied to estimate, apply empty
        // eligibility data to remove it from estimate
        this.applyToEstimate({ shouldExit: false });
      }
    });
  };

  fetchEstimateId() {
    if (this.state.estimateId) {
      return Promise.resolve(this.state.estimateId);
    }
    const benefit_plan = _.get(this.state, 'benefits.benefit_plans[0]', {});
    return this.apiCall('/api/v1/estimates', {
      method: 'POST',
      data: {
        first_name: benefit_plan.first_name,
        last_name: benefit_plan.last_name,
      },
    })
      .then((resp) => {
        this.setState({
          estimateId: resp.id,
          estimateIsNew: true,
        });
        return resp.id;
      })
      .catch((err) => {
        const message = 'Error fetching estimate id for insurance benefits';
        logger.error({ err }, message);
      });
  }

  applyToEstimate = ({ benefit_frames = [], benefit_exams = [], shouldExit = true } = {}) => {
    this.setState({ loading: true });
    const { benefits: { benefit_eligibility_id } } = this.state;
    const data = {
      frame_benefits: benefit_frames,
      exam_benefits: benefit_exams,
      // with the new data model, '[exam/frame]_benefit_id' have been replaced
      // with 'insurance_eligibility_item_id' and so the payload needs to have
      // the 'benefit_eligibility_id' included so as to not 500 when hitting
      // the 'api/v1/estimates/<estimate_id>/insurance-benefits' endpoint
      ...((shouldExit) && { benefit_eligibility_id }),
    };
    this.fetchEstimateId()
      // eslint-disable-next-line arrow-body-style
      .then((estimate_id) => {
        return this.apiCall(`/api/v1/estimates/${estimate_id}/insurance-benefits`, {
          method: 'PUT',
          data,
        });
      })
      .then(() => {
        if (shouldExit) {
          this.exit();
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        const message = `Error applying insurance benefits to estimate_id: ${this.state.estimateId}`;
        logger.error({ err }, message);
      });
  };

  setReimbursementFollowUpInterest = (shouldFollowUp, carrierKey = null) => {
    this.setState({ loading: true });
    this.fetchEstimateId()
      .then((estimate_id) => {
        this.apiCall(`/api/v1/estimates/${estimate_id}/insurance-carrier-interest`, {
          method: 'POST',
          data: {
            carrier_key: carrierKey,
            should_reimbursement_follow_up: shouldFollowUp,
          },
        });
      })
      .then(() => {
        this.exit();
      })
      .catch((err) => {
        const message = `Error saving interest on OON carrier for estimate_id: ${this.state.estimate_id}`;
        logger.error({ err }, message);
      });
  };

  handleUpdateFilters = (filterGroup, newFilters) => {
    this.setState(
      /* This is very cautious so as to not overwrite nested filter values.
       * There may be a better way to do this, e.g. nesting less. */
      (state) => {
        const newFilterGroup = {
          [filterGroup]: _.assign({}, state.matrixFilters[filterGroup], newFilters),
        };
        return { matrixFilters: _.assign({}, state.matrixFilters, newFilterGroup) };
      },
    );
  };

  dismissError = () => {
    this.setState({
      error: null,
      benefits: {
        ...this.state.benefits,
        response_message: '',
      },
    });
  };

  renderContent() {
    if (!this.state.carrier) {
      if (inLoading(this.state)) return <SpinningLoader />;
      return (
        <CarrierContainer
          chooseCarrier={(carrier) => this.setState({ carrier })}
          skip={this.exit}
          showOON={!!this.state.estimateId}
          featureFlags={this.state.userData.features}
        />
      );
    }

    const hasBenefitFrames = (benefits) => benefits && benefits.benefit_frames && benefits.benefit_frames.length > 0;
    if (hasBenefits(this.state) && !unableToRetrieveBenefits(this.state)) {
      const hasFrameBenefits = hasBenefitFrames(this.state.benefits);
      return (
        <div>
          <BenefitsSummary
            carrier={this.state.carrier}
            benefits={this.state.benefits}
            applyToEstimate={() => this.applyToEstimate(this.state.benefits)}
            hasEstimate={!!this.state.estimateId}
            loading={inLoading(this.state)}
            resetSelectedCarrier={this.resetSelectedCarrier}
            patientName={(this.state.patientData && this.state.patientData.first_name)
              || ''}
            company={this.state.company}
            resetSelectedCompany={this.resetSelectedCompany}
            hasFramesOrContactLens={this.state.benefits.has_only_frames_or_contact_lens}
          />
          {hasFrameBenefits && (
            <BenefitsMatrix
              itemsWithBenefits={this.state.itemsWithBenefits}
              filters={this.state.matrixFilters}
              handleUpdateFilters={this.handleUpdateFilters}
              updateBenefitsMatrix={this.updateBenefitsMatrix}
            />
          )}
        </div>
      );
    }

    if (this.state.carrier.inNetwork) {
      return (
        <this.state.carrier.eligibilityForm
          carrier={this.state.carrier}
          loading={inLoading(this.state)}
          patientData={this.state.patientData}
          handleSubmit={this.handleEligibilitySubmit}
          handleUpdate={this.handlePatientDataUpdate}
          showIdField={
            this.state.showIdField
            || (this.state.benefits
              && this.state.benefits.response_message
                .toLowerCase()
                .indexOf('multiple members records found') !== -1)
          }
          toggleIdField={(val) => {
            this.setState({ showIdField: val });
            if (!val && this.state.benefits) this.setState({ benefits: null });
          }}
          errorMessage={(this.state.benefits && this.state.benefits.response_message)
            || this.state.error}
          company={this.state.company}
          resetSelectedCarrier={this.resetSelectedCarrier}
          carrierLogoUrl={carrierLogoUrl}
          handleFormSubmit={handleFormSubmit}
          dismissError={this.dismissError}
        />
      );
    }

    if (inLoading(this.state)) {
      return (
        <SpinningLoader />
      );
    }

    return (
      <OutOfNetworkPrompt
        carrier={this.state.carrier}
        setReimbursementFollowUpInterest={this.setReimbursementFollowUpInterest}
        resetSelectedCarrier={this.resetSelectedCarrier}
      />
    );
  }

  goBack = () => {
    if (hasBenefits(this.state)) {
      this.clearEligibilityData();
    } else if (this.state.carrier) {
      this.resetSelectedCarrier({ company: false });
    } else {
      this.exit();
    }
  };

  nextUrl() {
    if (!this.state.estimateId) {
      return process.env.POE_HOME_URL;
    }

    // redirect to /visit/:visitId/estimate/:estimateId
    if (this.state.redirectToPoeVisit && this.state.estimateId && this.state.visitId) {
      return `${process.env.POE_HOME_URL}/visit/${this.state.visitId}/estimate/${this.state.estimateId}`;
    }

    if (this.state.estimateIsNew) {
      return `https://${process.env.HELIOS_RETAIL_DOMAIN}/pos/orders/${this.state.estimateId}#customer/customer-search`;
    }

    return `https://${process.env.HELIOS_RETAIL_DOMAIN}/pos/orders/${this.state.estimateId}`;
  }

  exit = () => {
    window.location.href = this.nextUrl();
  };

  render() {
    return (
      <div>
        <Header
          userData={this.state.userData}
          goBack={this.goBack}
          loading={inLoading(this.state)}
        />
        <div {...styles.contentContainer}>
          <div {...styles.contentWrapper}>{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

render(
  <StateManager initialState={INITIAL_STATE} />,
  document.getElementById('app'),
);
