import React from 'react';

import { circle } from './styles';

function AnimatedLoader() {
  return (
    <>
      <span className={circle} />
      <span className={circle} id="second" />
      <span className={circle} id="third" />
    </>
  );
}

export default AnimatedLoader;
