import { Benefit,
  BenefitIconName,
  Benefits,
  BenefitSection,
  BenefitToDisplay,
  BenefitType,
  Carrier,
  CarrierKey,
  Company } from './types';
import { fmtCount, pluralSuffix } from './util';

interface BenefitsForDisplayProps {
  benefits: Benefits;
  carrier: Carrier;
  company: Company;
}

interface ReturnTypeBenefitsForDisplay {
  hasCaveat: boolean;
  benefitsToDisplay: BenefitToDisplay[]
  anyAvailable: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const benefitsForDisplay = (
  { benefits, carrier, company }: BenefitsForDisplayProps,
): ReturnTypeBenefitsForDisplay => {
  const hasBoeingBenefits = carrier.carrierKey === 'davis' && company === Company.Boeing;
  const anyAvailable = [...benefits.benefit_frames, ...benefits.benefit_exams].some(
    (b) => b.benefit_available,
  );
  const multipleFrame = benefits.benefit_frames.length > 1;
  const benefitsToDisplay: BenefitToDisplay[] = [];
  benefits.benefit_frames?.forEach((b, i) => {
    const hasCaveat = multipleFrame && i === 0;

    // For Superior Insurance Carrier, we only show one Frame Package benefit, all others stay the same
    if (carrier.carrierKey === CarrierKey.Superior) {
      benefitsToDisplay.push({
        benefitType: BenefitType.FramePackage,
        benefitAvailableOnDisplay: benefitAvailableOnDisplay(b, BenefitSection.Frames),
        copayDisplay: '',
        copayRequired: b.is_copay_required,
        benefitDescription: b.amount_benefit_display,
        hasBoeingBenefits,
        benefitIcon: BenefitIconName.Frame,
        benefitAvailable: b.benefit_available,
      });
    } else {
      benefitsToDisplay.push({
        benefitType: BenefitType.Frame,
        benefitAvailableOnDisplay: benefitAvailableOnDisplay(b, BenefitSection.Frames),
        copayDisplay: b.amount_copay_display,
        copayRequired: b.is_copay_required,
        benefitDescription: coverageAmountDisplay(
          b,
          `pair${pluralSuffix(b.units_max)} of frames`,
          hasCaveat,
          hasBoeingBenefits,
        ),
        hasBoeingBenefits,
        benefitIcon: BenefitIconName.Frame,
        benefitAvailable: b.benefit_available,
      });

      if (b.lens_benefit_available) {
        const benefitDescription = hasBoeingBenefits ? '$50 allowance' : 'Lenses are covered';
        benefitsToDisplay.push({
          benefitType: BenefitType.Lens,
          benefitAvailableOnDisplay: benefitAvailableOnDisplay(b, BenefitSection.Frames),
          copayRequired: false,
          benefitDescription,
          hasBoeingBenefits,
          benefitIcon: BenefitIconName.Lens,
          benefitAvailable: b.benefit_available,
        });
      }
    }
  });

  benefits.benefit_exams?.forEach((b) => {
    const baseInfo = {
      benefitType: BenefitType.Exam,
      benefitAvailableOnDisplay: benefitAvailableOnDisplay(b, BenefitSection.Exams),
      copayDisplay: b.amount_copay_display,
      copayRequired: b.is_copay_required,
      benefitDescription: '',
      benefitIcon: BenefitIconName.Exam,
    };

    if (b.has_retinal_imaging) {
      const pluralDescriptionSuffix = pluralSuffix(b.units_max);
      const retinalImagingDescription = 'Digital Retinal Imaging Service'
        .concat(pluralDescriptionSuffix);

      benefitsToDisplay.push({
        ...baseInfo,
        benefitType: BenefitType.DigitalReginalImaging,
        benefitDescription: coverageAmountDisplay(
          b,
          retinalImagingDescription,
        ),
        benefitAvailable: b.benefit_available,
      });

      return;
    }

    benefitsToDisplay.push({
      ...baseInfo,
      benefitDescription: coverageAmountDisplay(
        b,
        `in-store eye exam${pluralSuffix(b.units_max)}`,
      ),
      benefitAvailable: b.benefit_available,
    });
  });

  benefits.benefit_contact_lenses?.forEach((b) => {
    benefitsToDisplay.push({
      benefitType: BenefitType.ContactLenses,
      benefitAvailableOnDisplay: benefitAvailableOnDisplay(b, BenefitSection.ContactLenses),
      copayDisplay: b.amount_copay_display,
      copayRequired: b.is_copay_required,
      benefitDescription: coverageAmountDisplay(b, `${b.benefit_name}${pluralSuffix(b.units_max)}`),
      benefitIcon: BenefitIconName.ContactLenses,
      benefitAvailable: b.benefit_available,
    });
  });

  benefits.benefit_contact_fittings?.forEach((b) => {
    benefitsToDisplay.push({
      benefitType: BenefitType.ContactLensFit,
      benefitAvailableOnDisplay: benefitAvailableOnDisplay(b, BenefitSection.ContactFittings),
      copayDisplay: b.amount_copay_display,
      copayRequired: b.is_copay_required,
      benefitDescription: coverageAmountDisplay(b, `${b.benefit_name || b.benefit_type}${pluralSuffix(b.units_max)}`),
      benefitIcon: BenefitIconName.ContactLensFit,
      benefitAvailable: b.benefit_available,
    });
  });

  benefits.benefit_contact_lenses_and_fittings?.forEach((b) => {
    benefitsToDisplay.push({
      benefitType: BenefitType.ContactLensesAndFit,
      benefitAvailableOnDisplay: benefitAvailableOnDisplay(b, BenefitSection.ContactLensesAndFittings),
      copayDisplay: b.amount_copay_display,
      copayRequired: b.is_copay_required,
      benefitDescription: coverageAmountDisplay(
        b,
        `${b.benefit_name.replace('+', '&')}${pluralSuffix(b.units_max)}`,
      ),
      benefitIcon: BenefitIconName.ContactLensFit,
      benefitAvailable: b.benefit_available,
    });
  });

  return {
    hasCaveat: multipleFrame,
    benefitsToDisplay,
    anyAvailable,
  };
};

const benefitAvailableOnDisplay = (b: Benefit, benefitSection: BenefitSection) => {
  if (b.benefit_available) return null;
  if (b.benefit_available_on && b.benefit_available_on_display) {
    return `Expired Benefit: Available again on ${b.benefit_available_on_display}`;
  }

  if (benefitSection === BenefitSection.ContactFittings && b.amount_copay > 45) {
    // if a contact lens fitting has a copay larger than $45.00,
    // then the benefit should be surfaced as unavailable because
    // the copay is larger than the cost of a contact lens exam
    return 'Copay exceeds out of pocket expense';
  }

  return 'This benefit has expired or been used';
};

const coverageAmountDisplay = (
  b: Benefit,
  description: string,
  hasCaveat = false,
  hasBoeingBenefits = false,
) => {
  if (b.has_retinal_imaging) {
    return `${b.units_max} ${description}`;
  }

  if (hasBoeingBenefits) {
    if (b.insurance_eligibility_type === 'frame') {
      return '$90 allowance';
    }
  }

  return (
    b.is_fully_covered
      ? `${fmtCount(b.units_max)} ${description}`
      : `${b.amount_allowance_display} allowance${hasCaveat ? '*' : ''}`
  );
};
