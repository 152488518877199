/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';

import { styles } from '../styles';

import AnimatedLoader from '../animated-loader';
import Error from '../error';

function UnitedEligibilityForm({
  carrier,
  carrierLogoUrl,
  dismissError,
  loading,
  patientData,
  handleFormSubmit,
  handleSubmit,
  handleUpdate,
  errorMessage,
  resetSelectedCarrier,
}) {
  const [isBenefitCheckLoading, setBenefitCheckLoading] = useState(false);

  const captureState = (e) => handleUpdate(e.target.name, e.target.value);

  let fieldExtras = {
    ...styles.formField,
    disabled: loading,
  };

  if (errorMessage) {
    fieldExtras = { ...fieldExtras, ...styles.errorField };
  }

  const { first_name, last_name, subscriber_dob, zip_code, _id_field } = patientData;

  return (
    <>
      <div {...styles.carrierPill} onClick={resetSelectedCarrier}>
        <img
          {...styles.carrierLogo}
          {...styles.logoPadding}
          alt={carrier.name}
          src={carrierLogoUrl(carrier.logo)}
        />
        <button {...styles.editButton} type="button">Edit</button>
      </div>
      <h3 {...styles.carrierName}>Enter member info to check benefits</h3>
      <Error dismissError={dismissError} message={errorMessage} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // if the form submit button has already been pressed to check
          // benefits, the button should be disabled, otherwise, make the
          // API call to get insurance benefits
          const patientDataForm = { first_name, last_name, zip_code, subscriber_dob, _id_field };
          return isBenefitCheckLoading ? null : (
            handleFormSubmit({
              handleSubmit,
              setBenefitCheckLoading,
              patientData: patientDataForm,
            })
          );
        }}
      >
        <div {...styles.formContainer}>
          <label htmlFor="first_name" {...styles.formFieldRow}>
            <span {...styles.formLabel}>First name</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="text"
              value={patientData.first_name}
              name="first_name"
            />
          </label>
          <label htmlFor="last_name" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Last name</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="text"
              value={patientData.last_name}
              name="last_name"
            />
          </label>
        </div>
        <div {...styles.formContainer}>
          <label htmlFor="zip_code" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Zip code</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="text"
              value={patientData.zip_code}
              name="zip_code"
              placeholder="00000"
            />
          </label>
          <label htmlFor="subscriber_dob" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Date of birth</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="date"
              value={patientData.subscriber_dob}
              name="subscriber_dob"
            />
          </label>
        </div>
        <h3 {...styles.carrierName}>Member Subscriber ID</h3>
        <div {...styles.formContainer} {...styles.extraMargin}>
          <input
            {...fieldExtras}
            onChange={captureState}
            type="text"
            value={patientData._id_field}
            placeholder="000000000"
            name="_id_field"
            data-browser-test-show-member-id-field
          />
        </div>
        <div {...styles.formButtonContainer}>
          <button
            {...styles.primaryButton}
            disabled={(loading && !isBenefitCheckLoading)}
            data-browser-test-check-for-benefits
            type="submit"
          >
            {isBenefitCheckLoading ? <AnimatedLoader /> : 'Check for benefits'}
          </button>
        </div>
      </form>
    </>
  );
}

export default UnitedEligibilityForm;
