/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-children-prop */
import React from 'react';
import _ from 'lodash';

import { styles } from './styles';
import { formatPrice } from './util';

const mock_item_default = {
  quantity: 1,
  amount: 0,
  is_high_index: false,
  is_photochromics: false,
  is_progressives: false,
  is_sun: false,
  is_ultra_high_index: false,
  is_flash: false,
  // Not needed for insurance calc but needed for display
  is_prism: false,
  base_price: 0,
};

const default_filters = {
  base_prices: {
    95: true,
    145: true,
    195: true,
  },
  prescription_types: {
    single_vision: true,
    is_progressives: true,
  },
  lens_type: {
    poly: true,
    is_high_index: false,
    is_ultra_high_index: false,
    demo: false,
  }, // TODO account for CR-39
  lens_coating: {
    standard: true,
    is_photochromics: false,
    is_flash: false,
  },
  is_sun: {
    is_sun: false,
  },
  is_prism: {
    is_prism: false, // TODO handle pricing for prism on backend, add UI
  },
};

// TODO refactor other places where these are duped to use this lookup
const filterFriendlyNames = {
  // Group level
  prescription_types: '℞ type',
  base_prices: 'Base price',
  lens_type: 'Lens type',
  lens_coating: 'Lens modifications',
  // Input labels
  single_vision: 'Single vision',
  is_progressives: 'Progressives',
  poly: 'Polycarbonate',
  is_high_index: '1.67 High Index',
  is_ultra_high_index: '1.74 Ultra High Index',
  cr_39: 'CR-39',
  demo: 'Demo',
  standard: 'Standard',
  is_photochromics: 'Photochromic',
  is_flash: 'Flash',
};

const labels = {
  is_flash: 'flash mirrored',
  is_sun: 'sun',
  is_photochromics: 'light-responsive',
  prism: 'prism',
};

function addTruthyFilters(filters) {
  return (item) => (
    _.map(filters, (v, k) => _.set(_.assign({}, item), k, v))
  );
}

function mapFilterOntoItems(acc, filterData) {
  const truthyFilters = _.pickBy(filterData);
  // Do not apply filters if none are truthy (e.g. if the item isn't sun/flash)
  return !_.isEmpty(truthyFilters) ? _.flatten(_.map(acc, addTruthyFilters(truthyFilters))) : acc;
}

function itemIsPossible(item) {
  if (item.is_sun) {
    return !(item.is_photochromics || item.is_ultra_high_index);
  }

  if (item.is_photochromics) {
    return !item.is_ultra_high_index;
  }

  return !item.is_flash;
}

export const makeMockItem = (values, index) => (
  _.assign(
    { estimate_item_id: index },
    mock_item_default,
    _.pick(values, _.keys(mock_item_default)),
  )
);

const mockItemsFromFilters = (filters) => {
  let itemData = _.reduce(
    _.omit(_.defaults(filters, default_filters), ['base_prices']),
    mapFilterOntoItems,
    // eslint-disable-next-line no-shadow
    _.map(_.pickBy(filters.base_prices, _.identity), (_, price) => {
      // eslint-disable-next-line radix
      const base_price = parseInt(price) * 100;
      return { amount: base_price, base_price };
    }),
  );
  itemData = _.filter(itemData, itemIsPossible);
  return _.map(itemData, makeMockItem);
};

export class BenefitsMatrix extends React.Component {
  constructor(props) {
    super(props);
    this.tabs = React.createRef();
  }

  componentDidMount() {
    const { updateBenefitsMatrix, filters } = this.props;
    updateBenefitsMatrix(mockItemsFromFilters(filters));
  }

  componentDidUpdate(prevProps) {
    const { updateBenefitsMatrix, filters } = this.props;
    if (filters !== prevProps.filters) {
      updateBenefitsMatrix(mockItemsFromFilters(filters));
    }
  }

  renderTabs() {
    const { filters, handleUpdateFilters } = this.props;
    const tabsAndFilters = {
      Eyeglasses: { is_sun: false },
      Sunglasses: { is_sun: true },
    };
    return _.map(
      tabsAndFilters,
      (sunFilter, title) => {
        const tabStyle = (
          _.isEqual(sunFilter, filters.is_sun)
            ? styles.activeMatrixTab : styles.matrixTab
        );
        return (
          <div
            {...tabStyle}
            children={title}
            key={_.snakeCase(title)}
            onClick={() => handleUpdateFilters('is_sun', sunFilter)}
          />
        );
      },
    );
  }

  renderBenefits() {
    const { itemsWithBenefits } = this.props;
    const grouped = _.groupBy(
      itemsWithBenefits,
      (item) => (item.is_progressives ? 'Progressive Prescription' : 'Single Vision Prescription'),
    );
    return _.map(
      grouped,
      (itemGroup, groupName) => (
        <Section key={groupName} itemGroup={itemGroup} groupName={groupName} />
      ),
    );
  }

  render() {
    const {
      itemsWithBenefits,
      filters,
      handleUpdateFilters,
    } = this.props;
    return itemsWithBenefits ? (
      <div id="benefits-matrix">
        <div {...styles.benefitsMatrix}>
          <div {...styles.benefitsMatrixTabs} children={this.renderTabs()} ref={this.tabs} />
          <div {...styles.benefitsMatrixBody}>
            <div {...styles.benefitsMatrixFilters}>
              <Filters
                currentFilters={filters}
                handleUpdateFilters={handleUpdateFilters}
              />
            </div>
            <div {...styles.benefitsMatrixBenefitsList} children={this.renderBenefits()} />
          </div>
        </div>
      </div>
    ) : null;
  }
}

const buildChangeHandler = (handleUpdateFilters, filterGroup, key) => (evt) => {
  // TODO maybe drop `key` in favor of evt.target.value if possible
  handleUpdateFilters(filterGroup, { [key]: evt.target.checked });
};

function Checkbox(filterGroup, handleUpdateFilters, value, key) {
  const friendlyName = filterFriendlyNames[key];
  return (
    <div {...styles.benefitsMatrixFilter} key={`${key}-checkbox`}>
      <input
        {...styles.filterInput}
        type="checkbox"
        id={key}
        value={key}
        checked={value}
        onChange={buildChangeHandler(handleUpdateFilters, filterGroup, key)}
      />
      <label htmlFor={key} children={friendlyName} />
    </div>
  );
}

function PriceCheckbox(filterGroup, handleUpdateFilters, value, key) {
  return (
    <div {...styles.benefitsMatrixFilter} key={`${key}-checkbox`}>
      <input
        {...styles.filterInput}
        type="checkbox"
        id={key}
        value={key}
        checked={value}
        onChange={buildChangeHandler(handleUpdateFilters, filterGroup, key)}
      />
      <label htmlFor={key} children={`$${key}`} />
    </div>
  );
}

function FilterFieldset(filters, handleUpdateFilters, Input, filterGroup) {
  return (
    <fieldset {...styles.filterFieldset} key={`${filterGroup}-filters`}>
      <legend
        {...styles.filterLegend}
        key={`${filterGroup}-legend`}
        children={filterFriendlyNames[filterGroup]}
      />
      {_.map(filters[filterGroup], _.partial(Input, filterGroup, handleUpdateFilters))}
    </fieldset>
  );
}

function Filters({ currentFilters, handleUpdateFilters }) {
  const fieldsets = {
    prescription_types: Checkbox,
    base_prices: PriceCheckbox,
    lens_type: Checkbox,
    lens_coating: Checkbox,
  };

  return (
    <form
      key="benefits-item-filters"
      {...styles.benefitsItemFilters}
      children={
        _.map(fieldsets, _.partial(FilterFieldset, currentFilters, handleUpdateFilters))
      }
    />
  );
}

const formatItemName = (item) => {
  const prescriptionType = item.is_progressives ? 'Progressive ℞' : 'Single Vision ℞';
  let lensType;
  if (item.is_high_index) {
    lensType = '1.67 High Index';
  } else if (item.is_ultra_high_index) {
    lensType = '1.74 Ultra High Index';
  } else {
    lensType = 'Standard polycarbonate';
  } // TODO account for CR-39
  const modifiers = _.join(
    _.reduce(
      labels,
      (acc, label, key) => {
        if (item[key]) {
          return acc.concat(label);
        }
        return acc;
      },
      [],
    ),
    ' ',
  );
  return [prescriptionType, _.trim(_.join([_.capitalize(modifiers), lensType], ' '))];
};

function Section({ itemGroup, groupName }) {
  let lensType;
  let prescriptionType;
  return [
    <h1
      {...styles.benefitsMatrixSectionHeader}
      children={groupName}
      key={_.snakeCase(groupName)}
    />,
    ..._.map(itemGroup, (item, index) => {
      [prescriptionType, lensType] = formatItemName(item);
      return (
        <div
          key={`insurance-item-${_.snakeCase(groupName)}-${index}`}
          {...styles.benefitsMatrixItem}
        >
          <div {...styles.benefitsMatrixTitle}>
            <span children={`Base price: ${formatPrice(item.amount)}`} />
            <br />
            <strong children={prescriptionType} />
            <br />
            <span {...styles.benefitsMatrixDetail} children={lensType} />
          </div>
          <div {...styles.benefitsMatrixPrice}>
            <del {...styles.benefitMatrixDetail}>({formatPrice(item.amount)})</del>
            {'\u00A0' /* LMAO this is how to do an nbsp */}
            {formatPrice(item.amount - item.benefit_amount)}
          </div>
        </div>
      );
    }),
  ];
}
