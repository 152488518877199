import React from 'react';
import { css } from 'glamor';

import { BenefitToDisplay, BenefitType } from './types';
import { styles } from './styles';
import { benefitImage } from './util';

const benefitDisabled = css({
  '> *': {
    backgroundColor: '#f8f8f8',
    border: '1px solid #E1E5E6',
    borderRadius: '6px',
    color: '#a1a4a6',
  },

  '> * div': {
    backgroundColor: '#f8f8f8',
  },

  '> * p': {
    color: '#a1a4a6',
  },

  '> * div div': {
    borderRadius: '0 0 5px 5px',
    boxSizing: 'border-box',
  },

  '> * span div p': {
    backgroundColor: '#e1e5e6',
    borderRadius: '0 0 5px 5px',
    color: '#a1a4a6',
  },
});

interface BenefitProps {
  benefit: BenefitToDisplay;
}

function BenefitItem({ benefit }: BenefitProps) {
  return (
    <div
      {...styles.benefitWrapper}
      {...(benefit.hasBoeingBenefits && benefit.benefitType === BenefitType.Lens
        ? styles.benefitWrapperBoeing
        : {})}
      {...(benefit.benefitAvailableOnDisplay ? benefitDisabled : {})}
    >
      <div {...styles.benefitChip}>
        <span {...styles.benefitHeaderWrapper}>
          <p {...styles.benefitHeader}>{benefit.benefitType}</p>
        </span>
        <div {...styles.benefitContent}>
          <figure {...styles.benefitIconWrapper}>
            <img
              {...styles.benefitIcon}
              className={
                benefit.benefitType === BenefitType.ContactLensFit
                || benefit.benefitType === BenefitType.ContactLensesAndFit
                || benefit.benefitType === BenefitType.Exam
                || benefit.benefitType === BenefitType.DigitalReginalImaging
                  ? 'double-icon'
                  : undefined
              }
              src={benefitImage(benefit)}
              alt={benefit.benefitIcon || benefit.benefitType}
            />
          </figure>
          <div
            {...styles.benefitDetail}
            {...(benefit.hasBoeingBenefits
            && benefit.benefitType === BenefitType.Frame
              ? styles.benefitDetailBoeing
              : {})}
          >
            <p
              {...styles.benefitDescriptionHeader}
              {...(benefit.hasBoeingBenefits
              && benefit.benefitType === BenefitType.Frame
                ? styles.benefitDescriptionHeaderBoeing
                : {})}
            >
              {benefit.benefitDescription}
            </p>
            <p
              {...styles.benefitDescriptionDetail}
              {...(benefit.hasBoeingBenefits
              && benefit.benefitType === BenefitType.Frame
                ? styles.benefitDescriptionDetailBoeing
                : {})}
            >
              {benefit.copayRequired && benefit.copayDisplay
                ? `${benefit.copayDisplay} copay`
                : 'No copay'}
            </p>
            <p {...styles.benefitDescriptionHeader}>
              {benefit.benefitAvailable ? (
                <>
                  Available
                  {' '}
                  <span {...styles.check}>&#10003;</span>
                  {' '}
                </>
              ) : (
                <>
                  Not available
                  {' '}
                  <span {...styles.redColor}>&#10006;</span>
                </>
              )}
            </p>
            {benefit.hasBoeingBenefits
              && benefit.benefitType === BenefitType.Lens && (
                <p
                  {...styles.benefitDescriptionDetail}
                  {...(benefit.hasBoeingBenefits
                    ? styles.boeingLensBenefitDisclaimer
                    : {})}
                >
                  Note: Not available for progressives,
                  <br />
                  blue-light-filtering, or high-index lenses.
                </p>
            )}
          </div>
        </div>
        {benefit.benefitAvailableOnDisplay ? (
          <span
            {...styles.foot}
            {...(benefit.hasBoeingBenefits
            && benefit.benefitType === BenefitType.Lens
              ? styles.boeingFoot
              : {})}
          >
            <div {...styles.benefitWarningWrapper}>
              <p {...styles.benefitWarning}>
                {benefit.benefitAvailableOnDisplay}
              </p>
            </div>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default BenefitItem;
