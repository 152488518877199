/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';

import { styles } from '../styles';

import AnimatedLoader from '../animated-loader';
import Error from '../error';
import { getIdLabel } from '../util';

function DavisEligibilityForm({
  carrier,
  carrierLogoUrl,
  company,
  dismissError,
  loading,
  patientData,
  handleFormSubmit,
  handleSubmit,
  handleUpdate,
  errorMessage,
  resetSelectedCarrier,
}) {
  const [isBenefitCheckLoading, setBenefitCheckLoading] = useState(false);

  const captureState = (e) => handleUpdate(e.target.name, e.target.value);

  let fieldExtras = {
    ...styles.formField,
    disabled: loading,
  };

  if (errorMessage) {
    fieldExtras = { ...fieldExtras, ...styles.errorField };
  }

  const idLabel = getIdLabel(carrier, company);
  const { first_name, last_name, subscriber_dob, _id_field } = patientData;

  // TODO: Remove all of the below once boeing feature flag is live
  // and flipped on for all facilities
  return (
    <>
      <div {...styles.carrierPillContainer}>
        <div
          {...styles.carrierPill}
          onClick={() => resetSelectedCarrier({ company: true })}
        >
          <img
            {...styles.carrierLogo}
            {...styles.logoPadding}
            alt={carrier.name}
            src={carrierLogoUrl(carrier.logo)}
          />
          <button {...styles.editButton} type="button">Edit</button>
        </div>
      </div>
      <h3 {...styles.carrierName}>Enter member info to check benefits</h3>
      <Error dismissError={dismissError} message={errorMessage} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // if the form submit button has already been pressed to check
          // benefits, the button should be disabled, otherwise, make the
          // API call to get insurance benefits
          const patientFormData = { first_name, last_name, subscriber_dob, _id_field };
          return isBenefitCheckLoading ? null : (
            handleFormSubmit({
              handleSubmit,
              setBenefitCheckLoading,
              patientData: patientFormData,
            })
          );
        }}
      >
        <div {...styles.formContainer}>
          <label htmlFor="first_name" {...styles.formFieldRow}>
            <span {...styles.formLabel}>First name</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="text"
              value={patientData.first_name}
              name="first_name"
            />
          </label>
          <label htmlFor="last_name" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Last name</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="text"
              value={patientData.last_name}
              name="last_name"
            />
          </label>
        </div>
        <div {...styles.formContainer}>
          <label htmlFor="subscriber_dob" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Date of birth</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="date"
              value={patientData.subscriber_dob}
              name="subscriber_dob"
            />
          </label>
          <label htmlFor="_id_field" {...styles.formFieldRow}>
            <span {...styles.formLabel}>{idLabel}</span>
            <input
              {...fieldExtras}
              onChange={captureState}
              type="text"
              value={patientData._id_field}
              placeholder="000000000"
              name="_id_field"
            />
          </label>
        </div>
        <div {...styles.formButtonContainer}>
          <button
            {...styles.primaryButton}
            disabled={loading && !isBenefitCheckLoading}
            type="submit"
          >
            {isBenefitCheckLoading ? <AnimatedLoader /> : 'Check for benefits'}
          </button>
        </div>
      </form>
    </>
  );
}

export default DavisEligibilityForm;
