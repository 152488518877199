/* eslint-disable import/prefer-default-export */

import { BenefitIconName,
  BenefitToDisplay,
  BenefitType,
  InsuranceBenefit } from './types';

export const normalizeDate = (date: string) => date.replace(/^([01]?[0-9])\/([0-3]?[0-9])\/([0-9]{4})$/, '$3-$1-$2');

export const formatPrice = (cents: number) => `$${Math.round(cents / 100)}`;

export const fmtCount = (n: number) => ({
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
}[n]
  || n
  || '');

// e.g. `alumn${pluralSuffix(3, 'i', 'us')}`
export const pluralSuffix = (n: number, plural = 's', singular = ''): string => (String(n) === '1' ? singular : plural);

export const carrierLogoUrl = (logoFile?: string) => (logoFile ? `./static/carriers/img/${logoFile}` : '');

export const benefitImage = ({
  benefitAvailableOnDisplay,
  benefitIcon,
}: BenefitToDisplay) => {
  let iconSlug = benefitIcon;

  if (benefitAvailableOnDisplay) {
    // if benefit is not available, show disabled icon
    iconSlug = `${iconSlug}-disabled` as BenefitIconName;
  }

  return `./static/${iconSlug}.svg`;
};

export const isBenefitForGlasses = (benefityType: BenefitType) => [BenefitType.Frame, BenefitType.Lens, BenefitType.FramePackage].includes(
  benefityType,
);

export const isContactLensBenefit = (benefityType: BenefitType) => [BenefitType.ContactLenses].includes(benefityType);

export const isGlassesOrContactLensBenefit = (benefityType: BenefitType) => [
  BenefitType.Frame,
  BenefitType.Lens,
  BenefitType.ContactLenses,
  BenefitType.FramePackage,
].includes(benefityType);

export const formatBenefits = (benefits: InsuranceBenefit) => (benefits
    && benefits.exam_benefits.length
    && benefits.frame_benefits.length
  ? {
    is_success: true,
    benefit_frames: benefits.frame_benefits,
    benefit_exams: benefits.exam_benefits,
    benefit_eligibility_id: benefits.benefit_eligibility_id,
    benefit_contact_lenses: benefits.contact_lens_benefits,
    benefit_contact_lenses_and_fittings:
          benefits.contact_lens_and_fitting_benefits,
    benefit_contact_fittings: benefits.contacts_fitting_benefits,
    has_only_frames_or_contact_lens:
          benefits.has_only_frames_or_contact_lens,
  }
  : null);

export const getIdLabel = (carrier: { name: string; }, company: any) => {
  if (carrier.name === 'Superior Vision') {
    return 'Subscriber ID';
  }
  if (company) {
    return `Employee ID for ${company}`;
  }
  return 'Member ID (Employee ID for GE)';
};
