import React from 'react';
import { StyleAttribute } from 'glamor';
import { Carrier } from '../../types';
import CarrierLogo from './carrier-logo';

interface Props {
  carrier: Carrier;
  onClick: (carrier: Carrier) => void;
  styles: { [key: string]: StyleAttribute };
}
export default function CarrierItem(props: Props) {
  const { carrier, styles, onClick } = props;

  return (
    <li {...styles.carrierListItem} key={carrier.name}>
      <button
        type="button"
        onClick={() => onClick(carrier)}
        {...styles.carrierListBtn}
        {...(!carrier.logo && styles.carrierListTextBtn)}
        data-browser-test-insurance-carrier={carrier.name}
      >
        {carrier.logo ? (
          <CarrierLogo
            carrierLogo={carrier.logo}
            carrierName={carrier.name}
            styles={styles}
          />
        ) : carrier.name}
      </button>
    </li>
  );
}
