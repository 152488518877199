import DavisEligibilityForm from './forms/davis-eligibility-form';
import UnitedEligibilityForm from './forms/united-eligibility-form';
import EyeMedEligibilityForm from './forms/eyemed-eligibility-form';
import GuardianEligibilityForm from './forms/guardian-eligibility-form';
import { Carrier, CarrierKey } from './types';

export const EYE_MED_FEATURE_FLAG = 'shop_app_insurance_eyemed';
export const GUARDIAN_FEATURE_FLAG = 'shop_app_insurance_guardian';
export const CAREFIRST_FEATURE_FLAG = 'shop_app_insurance_carefirst';
export const CIGNA_INSURANCE_FEATURE_FLAG = 'shop_app_insurance_cigna';
export const SUPERIOR_INSURANCE_FEATURE_FLAG = 'shop_app_insurance_superior';

export const inNetworkCarriers: Carrier[] = [
  {
    id: 'guardian',
    name: 'Guardian',
    logo: 'guardian-logo.png',
    carrierKey: CarrierKey.Guardian,
    phoneNumber: '877.393.7363',
    inNetwork: true,
    featureFlagRequired: GUARDIAN_FEATURE_FLAG,
    eligibilityForm: GuardianEligibilityForm,
  },
  {
    id: 'carefirst',
    name: 'CareFirst',
    logo: 'carefirst-logo.png',
    carrierKey: CarrierKey.CareFirst,
    phoneNumber: '800-783-5602',
    inNetwork: true,
    featureFlagRequired: CAREFIRST_FEATURE_FLAG,
    eligibilityForm: GuardianEligibilityForm,
  },
  {
    id: 'eyemed',
    name: 'EyeMed',
    logo: 'eyemed-logo.png',
    carrierKey: CarrierKey.EyeMed,
    phoneNumber: '888.550.2583',
    inNetwork: true,
    featureFlagRequired: EYE_MED_FEATURE_FLAG,
    eligibilityForm: EyeMedEligibilityForm,
  },
  {
    id: 'cigna',
    name: 'Cigna',
    logo: 'cigna-logo.png',
    carrierKey: CarrierKey.Cigna,
    phoneNumber: '888.353.2653',
    inNetwork: true,
    featureFlagRequired: CIGNA_INSURANCE_FEATURE_FLAG,
    eligibilityForm: EyeMedEligibilityForm,
  },
  {
    id: 'blue-cross-blue-shield-fep',
    name: 'Blue Cross Blue Shield FEP Vision',
    logo: 'blue-cross-blue-shield-fep-logo.png',
    carrierKey: CarrierKey.Davis,
    phoneNumber: '1 (877) 235-5316',
    inNetwork: true,
    eligibilityForm: DavisEligibilityForm,
  },
  {
    id: 'davis',
    name: 'Davis Vision',
    logo: 'davis-logo.png',
    carrierKey: CarrierKey.Davis,
    inNetwork: true,
    primary: true,
    phoneNumber: '800.433.9375',
    eligibilityForm: DavisEligibilityForm,
  },
  {
    id: 'superior',
    name: 'Superior Vision',
    logo: 'superior-logo.png',
    carrierKey: CarrierKey.Superior,
    inNetwork: true,
    primary: true,
    phoneNumber: '1 (877) 235-5317',
    eligibilityForm: DavisEligibilityForm,
  },
  {
    id: 'spectera',
    name: 'Spectera',
    logo: 'spectera-logo.png',
    carrierKey: CarrierKey.United,
    phoneNumber: '800.638.3120',
    inNetwork: true,
    eligibilityForm: UnitedEligibilityForm,
  },
  {
    id: 'unitedhealthcare',
    name: 'United Healthcare',
    logo: 'unitedhealthcare-logo.png',
    carrierKey: CarrierKey.United,
    phoneNumber: '800.638.3120',
    inNetwork: true,
    primary: true,
    eligibilityForm: UnitedEligibilityForm,
  },
];

export const outOfNetworkCarriers: Carrier[] = [
  {
    name: 'aetna',
    carrierKey: CarrierKey.Aetna,
    logo: 'aetna-logo.png',
  },
  {
    name: 'Anthem',
    carrierKey: CarrierKey.Anthem,
    logo: 'anthem-logo.png',
  },
  {
    name: 'EyeMed',
    carrierKey: CarrierKey.EyeMed,
    logo: 'eyemed-logo.png',
    featureFlagExcludes: EYE_MED_FEATURE_FLAG,
  },
  {
    name: 'Cigna',
    carrierKey: CarrierKey.Cigna,
    logo: 'cigna-logo.png',
    featureFlagExcludes: CIGNA_INSURANCE_FEATURE_FLAG,
  },
  {
    name: 'Humana',
    carrierKey: CarrierKey.Humana,
    logo: 'humana-logo.png',
  },
  {
    name: 'Vsp',
    carrierKey: CarrierKey.Vsp,
    logo: 'vsp-logo.png',
  },
  {
    name: 'Other Carriers',
    carrierKey: CarrierKey.Other,
  },
];

export const CARRIERS = inNetworkCarriers.concat(...outOfNetworkCarriers);
