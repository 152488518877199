export const INITIAL_STATE = {
  userData: {
    features: null,
    facility: {
      name: null,
    },
    environment: {
      is_training: false,
    },
  },
  carrier: null,
  patientData: {
    first_name: '',
    last_name: '',
    zip_code: '',
    subscriber_dob: '',
    _id_field: '',
  },
  matrixFilters: {
    is_sun: {
      is_sun: false,
    },
  },
  benefits: null,
  showIdField: null,
  estimateId: null,
  estimateIsNew: null,
};
