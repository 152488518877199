import React from 'react';

import { spinner, styles } from './styles';

function SpinningLoader() {
  return (
    <div {...styles.spinningLoaderContainer}>
      <div className={spinner} />
    </div>
  );
}

export default SpinningLoader;
