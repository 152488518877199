import { css, compose, hover, active, disabled, placeholder } from 'glamor';

export const styles = {
  header: css({
    backgroundColor: '#414b56',
    borderBottom: '0',
    boxShadow: '0 -1px 0 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    height: '80px',
    justifyContent: 'center',
    minHeight: '80px',
    margin: '0 auto',
    width: '100%',
  }),
  trainingHeader: css({
    backgroundColor: '#f1c40f',
  }),
  headerLink: css({
    alignItems: 'center',
    display: 'flex',
    maxWidth: '768px',
    padding: '0 20px',
    textDecoration: 'none',
    width: '100%',
  }),
  headerTextContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
  headerText: css({
    color: '#ffffff',
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: '20px',
    margin: '0',
    textAlign: 'center',
    width: '100%',
  }),
  backBtn: css({
    background: 'transparent',
    backgroundImage: 'url(./static/back-arrow.svg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
    border: 'none',
    height: '40px',
    left: '2px',
    padding: '0 0 0.9375rem 0 !important',
    position: 'absolute',
    textDecoration: 'none',
    top: '18px',
    width: '40px',
    zIndex: '800',
  }),
  textBtn: css({
    background: 'transparent',
    border: 'none',
    padding: '0 0 0.9375rem 0 !important',
    color: '#00A2E1',
    fontSize: '1rem',
    cursor: 'pointer',
  }),
  contentContainer: css({
    fontFamily: '"proxima-nova", "Helvetica", "Verdana", sans-serif',
    color: '#435560',
    maxWidth: '100rem',
    width: '100%',
    position: 'relative',
    flexGrow: '1',
    margin: '0px auto',
    background: 'rgb(248, 248, 248)',
    minHeight: '100%',
  }),
  contentWrapper: css({
    padding: '40px',
  }),
  formControl: compose(
    css({
      fontFamily: '"proxima-nova", "Helvetica", "Verdana", sans-serif',
      boxShadow: 'none',
      backgroundColor: '#fff',
      border: '1px solid rgba(161, 164, 166, 0.625)',
      borderRadius: '0',
      color: '#676f78',
      fontSize: '1rem',
      fontWeight: 'normal',
      margin: '0 0 0.9375rem 0',
      padding: '10px 18px',
      height: '60px',
      width: '100%',
    }),
    disabled({
      backgroundColor: '#f8f8f8',
    }),
  ),
  errorField: css({
    borderColor: '#E47C4C',
  }),
  errorText: css({
    color: '#E47C4C',
    paddingBottom: '18px',
    whiteSpace: 'pre-line',
  }),
  button: compose(
    css({
      background: '#00a2e1',
      border: '1px #0089bf solid',
      borderRadius: '0',
      boxShadow: '0 0 1px #e3e5e5, inset 0 0 1px #48bdec',
      color: '#fff',
      display: 'block',
      fontFamily: '"proxima-nova", "Helvetica", "Verdana", sans-serif',
      fontSize: '1rem',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: 'normal',
      marginBottom: '0.625rem',
      marginTop: '0',
      outline: '0',
      paddingBottom: '1.1875rem',
      paddingLeft: '1.875rem',
      paddingRight: '1.875rem',
      paddingTop: '1.1875rem',
      position: 'relative',
      textAlign: 'center',
      textShadow: '0 1px #0089bf',
      verticalAlign: 'top',
      WebkitAppearance: 'none',
      WebkitTapHighlightColor: 'rgba(255, 255, 255, 0)',
      whiteSpace: 'nowrap',
      width: '100%',
    }),
    hover({
      background: '#18b3f0',
      border: '1px #009bd9 solid',
      boxShadow: '0 0 1px #eaeceb, inset 0 0 1px #48cbff',
      textShadow: '0 1px #009bd9',
    }),
    active({
      background: '#009bd9',
      borderColor: '#0080b3',
      boxShadow: 'none',
      textShadow: '0 1px #0089bf',
      outline: '0',
    }),
    disabled({
      background: '#f8f8f8',
      borderColor: '#a1a4a6',
      color: '#a1a4a6',
      textShadow: 'none',
      boxShadow: 'none',
    }),
  ),
  benefitChipsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '25px 0',
  }),
  secondaryButton: compose(
    css({
      backgroundColor: 'white',
      color: '#00a2e1',
      textShadow: 'none',
    }),
    hover({
      backgroundColor: 'rgb(248, 248, 248)',
      textShadow: 'none',
    }),
    active({
      backgroundColor: 'rgb(248, 248, 248)',
      textShadow: 'none',
    }),
  ),
  benefitsTitle: css({
    fontFamily: 'Utopia',
    textAlign: 'center',
  }),
  benefitWrapper: css({
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    boxSizing: 'border-box',
    display: 'flex',
    flexBasis: '50%',
    flexDirection: 'column',
    height: '250px',
    justifyContent: 'space-between',
    margin: '10px auto',
    marginBottom: '18px',
    maxHeight: '250px',
    maxWidth: '317px',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  }),
  benefitWrapperBoeing: css({
    height: '288px',
    maxHeight: '288px',
    overflow: 'visible',
  }),
  benefitChip: css({
    alignItems: 'center',
    border: '1px solid #E1E5E6',
    borderRadius: '6px',
    '&:last-child': {
      borderBottom: '1px solid #E1E5E6',
    },
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  }),
  benefitHeaderWrapper: css({
    alignItems: 'center',
    borderBottom: '1px solid #E1E5E6',
    borderRadius: '6px 6px 0 0',
    display: 'flex',
    fontSize: '20px',
    height: '48px',
    justifyContent: 'center',
    paddingBottom: '5px',
    textAlign: 'center',
    width: '100%',
  }),
  benefitHeader: css({
    color: '#00a2e1',
    letterSpacing: '3px',
    margin: '0',
    maxWidth: '12em',
    width: '100%',
    wordWrap: 'break-word',
    textTransform: 'uppercase',
  }),
  benefitContent: css({
    marginBottom: '10px',
    padding: '1em',
  }),
  benefitIconWrapper: css({
    margin: '0 1em',
    marginBottom: '12px',
    verticalAlign: 'middle',
  }),
  benefitIcon: css({
    width: '90px',

    '.double-icon': {
      width: '165px',
    },
  }),
  benefitDetail: css({
    display: 'inline-block',
    verticalAlign: 'middle',
  }),
  benefitDescriptionHeader: css({
    fontSize: '1.4em',
    fontFamily: 'Utopia',
    fontWeight: '600',
    color: '#414b56',
    margin: '0.2em',
  }),
  benefitDescriptionDetail: css({
    fontSize: '1em',
    color: '#676F78',
    margin: '0.2em',
  }),
  benefitDetailBoeing: css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
  benefitDescriptionHeaderBoeing: css({
    flex: '1 1 auto',
    width: '100%',
  }),
  benefitDescriptionDetailBoeing: css({
    margin: '0 auto',
  }),
  boeingLensBenefitDisclaimer: css({
    margin: '0',
  }),
  benefitWarningWrapper: css({
    borderRadius: '0 0 5px 5px',
    maxWidth: '316px',
    textAlign: 'center',
    width: '100%',
  }),
  benefitWarning: css({
    backgroundColor: '#FFEEEA',
    borderRadius: '0 0 5px 5px',
    color: '#676F78',
    margin: '0',
    padding: '6px',
    verticalAlign: 'middle',
  }),
  benefitWarningLogo: css({
    width: '16px',
    height: '16px',
    marginRight: '8px',
    verticalAlign: 'middle',
  }),
  benefitsButton: css({
    width: '420px',
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  benefitsExtraInfo: css({
    textAlign: 'center',
    color: '#676F78',
    maxWidth: '420px',
    margin: 'auto',
  }),
  benefitsMatrix: css({
    backgroundColor: 'white',
    width: '100%',
    paddingBottom: '24px',
  }),
  benefitsMatrixFilter: css({
    padding: '5px 0',
    color: '#414b56',
    fontWeight: '300',
  }),
  benefitsMatrixFilters: css({
    margin: '2em 0 0 1em',
  }),
  benefitsMatrixBenefitsList: css({
    flex: '1',
  }),
  benefitsMatrixBody: css({
    display: 'flex',
  }),
  benefitsMatrixDetail: css({
    fontSize: '1em',
    color: '#676F78',
    paddingRight: '0.2em',
  }),
  benefitsMatrixHeader: css({
    paddingBottom: '24px',
  }),
  benefitsMatrixItem: css({
    alignItems: 'center',
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    display: 'flex',
    margin: '1em 2em 0',
    padding: '1em 2em',
  }),
  benefitsMatrixPrice: css({
    flex: '2',
    textAlign: 'right',
    width: '10%',
  }),
  benefitsMatrixTitle: css({
    flex: '8',
    width: '90%',
  }),
  benefitsMatrixSectionHeader: css({
    textAlign: 'center',
    fontWeight: '600',
    margin: '2em 0 1em 0',
  }),
  benefitsMatrixTabs: css({
    width: '100%',
    display: 'flex',
    marginTop: '24px',
  }),
  filterFieldset: css({
    marginTop: '1em',
    border: '0',
  }),
  filterInput: css({
    transform: 'scale(1.4)',
    width: '20px',
    marginRight: '8px',
  }),
  filterLegend: css({
    fontSize: '12px',
    fontWeight: '600',
  }),
  matrixTab: css({
    backgroundColor: '#F8F8F8',
    flex: '1',
    padding: '1em 0',
    textAlign: 'center',
  }),
  activeMatrixTab: css({
    backgroundColor: 'white',
    color: '#009BCB',
    flex: '1',
    padding: '1.5em 0',
    textAlign: 'center',
  }),
  carrierList: css({
    border: '1px solid #e1e5e6',
    borderRadius: '6px',
    margin: '0',
    padding: '0',
  }),
  carrierListHeader: css({
    color: '#414b56',
    fontSize: '24px',
    fontWeight: '600',
    margin: '0',
    marginBottom: '18px',
    textAlign: 'left',

    '&.outOfNetwork': {
      marginTop: '49px',
    },
  }),
  carrierListItem: css({
    listStyle: 'none',
    width: '100%',
    background: 'white',
    margin: '0',
    borderBottom: '1px solid #e1e5e6',

    '&:first-child': {
      borderRadius: '6px 6px 0 0',
    },

    '&:last-child': {
      borderRadius: '0 0 6px 6px',
      borderBottom: '0',
    },
  }),
  carrierListBtn: css({
    background: 'transparent',
    width: ['available', '-moz-available', '-webkit-fill-available'],
    display: 'block',
    border: 'none',
    padding: '0 0 0 30px',
    textAlign: 'left',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '14px 14px',
    backgroundImage: 'url(./static/right-arrow-icon.svg)',
    margin: '0 40px 0 0',
    cursor: 'pointer',
  }),
  carrierListTextBtn: css({
    textTransform: 'capitalize',
    color: '#435560',
    fontSize: '1rem',
    padding: '32px 34px 32px 50px',
  }),
  carrierLogo: css({
    maxHeight: '80px',
    maxWidth: '160px',
  }),
  carrierLogoContainer: css({
    height: '80px',
    display: 'flex',
    alignItems: 'center',
  }),
  carrierListFooter: css({
    textAlign: 'center',
  }),
  otherCarrier: css({
    margin: '0',
    padding: '29px 0 29px 18px',
  }),
  centeredBlock: css({
    margin: '0 auto',
    display: 'block',
  }),
  outOfNetworkContainer: css({
    backgroundColor: 'white',
    border: '1px solid #DEDEDE',
    borderRadius: '6px',
    padding: '4em 60px',
    marginTop: '30px',
    minHeight: '80%',
  }),
  outOfNetworkHeader: css({
    color: '#d6003c',
    fontFamily: 'proxima-nova',
    fontSize: '30px',
    fontWeight: '600',
    margin: '0',
    textAlign: 'center',
  }),
  outOfNetworkMessage: css({
    color: '#d6003c',
    fontFamily: 'proxima-nova',
    fontSize: '20px',
    margin: '0',
    padding: '18px 1em 0',
    textAlign: 'justify',
    textAlignLast: 'center',

    '&.oon-last-para': {
      paddingBottom: '63px',
    },
  }),
  outOfNetworkRadioButtonContainer: css({
    alignItems: 'center',
    display: 'flex',
    padding: '0 20px',
    width: '100%',
  }),
  outOfNetworkLabel: css({
    background: '#ffffff',
    border: '1px solid #e1e5e6',
    borderRadius: '50%',
    color: '#676f78',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '20px',
    height: '30px',
    margin: '0',
    marginRight: '10px',
    padding: '2px',
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '30px',

    '&.checked': {
      backgroundImage: 'url(./static/radio-checked.svg)',
      backgroundPosition: 'center',
    },
  }),
  outOfNetworkRadioButton: css({
    // hide native radio button styling for custom styling above
    opacity: '0',
    position: 'absolute',
  }),
  outOfNetworkText: css({
    color: '#676F78',
    display: 'inline-block',
    fontFamily: 'proxima-nova',
    fontSize: '20px',
    width: '475px',
  }),
  outOfNetworkButtonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '64px',
    padding: '0 20px',
    width: '100%',
  }),
  outOfNetworkButton: css({
    margin: '0',
    fontFamily: 'proxima-nova',
    fontWeight: '500',
  }),
  secondaryAction: css({
    display: 'block',
    textAlign: 'center',
    margin: '24px auto 0',
  }),
  davisLogo: css({
    height: '80px',
    paddingTop: '3px',
  }),
  davisBoeingLogo: css({
    objectFit: 'contain',
    width: '205px',
  }),
  formContainer: css({
    display: 'flex',
    width: '100%',
    marginBottom: '1em',
  }),
  errorMessageContainer: css({
    alignItems: 'center',
    backgroundColor: '#fbe5eb',
    borderRadius: '6px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px',
    textAlign: 'center',
    width: '100%',

    '&.errorWrap': {
      flexWrap: 'wrap',
    },
  }),
  errorMessage: css({
    color: '#d6003c',
    fontFamily: 'proxima-nova',
    fontSize: '16px',
    minHeight: '20px',
    margin: '0',
    padding: '24px 0',
    textAlign: 'center',
    width: '90%',
  }),
  errorIcon: css({
    boxSizing: 'border-box',
    display: 'flex',
  }),
  additionalErrorMessage: css({
    padding: '0 48px 24px',
    width: '100%',
  }),
  formFieldRow: css({
    boxSizing: 'border-box',
    width: '100%',

    '&:first-child': {
      marginRight: '18px',
    },

    '&.middle-child': {
      marginRight: '18px',
    },
  }),
  twoColumnedFormFieldRow: css({
    width: '48%',
  }),
  formLabel: css({
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: '16px',
    fontWeight: '600',
    display: 'block',
    color: '#a1a4a6',
    paddingBottom: '10px',
    height: '18px',
  }),
  formField: compose(
    css({
      alignItems: 'center',
      backgroundColor: 'white',
      borderColor: '#e4e6e8',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderWidth: '1px',
      boxSizing: 'border-box',
      color: '#414b56',
      cursor: 'pointer',
      fontFamily: '"proxima-nova", sans- serif',
      fontSize: '18px',
      height: '100%',
      justifyContent: 'center',
      maxHeight: '75px',
      outline: 'none',
      padding: '30px',
      width: '100%',
    }),
    placeholder({
      color: '#a1a4a6',
    }),
  ),
  formButtonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '50px',
    height: '60px',
  }),
  primaryButtonContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
  // Future plans to move this button as prototype for shared component library
  primaryButton: css({
    alignItems: 'center',
    background: '#00a2e1',
    border: '1px solid #00a2e1',
    borderRadius: '100px',
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: '600',
    justifyContent: 'center',
    outline: 'none',
    padding: '18px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    width: 'calc(50% - 9px)',
    WebkitTapHighlightColor: 'transparent',

    '&:disabled': {
      backgroundColor: '#e1e5e6',
      border: '1px solid #e1e5e6',
      color: '#a1a4a6',
      cursor: 'not-allowed',
    },

    '&:hover:not([disabled])': {
      background: '#3dbdee',
      border: '1px solid #3dbdee',
      color: '#ffffff',
      cursor: 'pointer',
    },

    '&:focus': {
      background: '#0089bf',
      border: '1px solid #0089bf',
      color: '#ffffff',
      cursor: 'pointer',
    },

    '&:active:not([disabled])': {
      background: '#0089bf',
      border: '1px solid #0089bf',
      color: '#ffffff',
      cursor: 'pointer',
    },
  }),
  secondaryButtonContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
  secondaryButtonUpdated: css({
    alignItems: 'center',
    background: '#f8f8f8',
    border: '1px solid #e1e5e6',
    borderRadius: '100px',
    color: '#00a2e1',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: '600',
    justifyContent: 'center',
    outline: 'none',
    marginBottom: '50px',
    padding: '18px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    width: 'calc(50% - 9px)',
    WebkitTapHighlightColor: 'transparent',

    '&:disabled': {
      border: '1px solid #e1e5e6',
      color: '#e1e5e6',
      cursor: 'not-allowed',
    },

    '&:hover:not([disabled])': {
      border: '2px solid #00a2e1',
      color: '#00a2e1',
      cursor: 'pointer',
    },

    '&:focus': {
      background: '#f8f8f8',
      border: '1px solid #0089bf',
      color: '#0089bf',
      cursor: 'pointer',
    },

    '&:active': {
      background: '#f8f8f8',
      border: '1px solid #0089bf',
      color: '#0089bf',
      cursor: 'pointer',
    },
  }),
  buttonAdditionalMargin: css({
    marginTop: '60px',
  }),
  carrierName: css({
    color: '#414b56',
    fontWeight: '600',
  }),
  extraMargin: css({
    margin: '0 0 24px 0',
  }),
  carrierPillContainer: css({
    display: 'flex',
  }),
  carrierPill: css({
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: '#e4e6e8',
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxSizing: 'border-box',
    color: '#414b56',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: '"proxima-nova", sans- serif',
    fontSize: '18px',
    justifyContent: 'space-between',
    outline: 'none',
    padding: '0 20px',
    width: '100%',
    height: '80px',

    '&.multi-pill': {
      marginRight: '18px',
    },
  }),
  carrierContactInfo: css({
    color: '#a1a4a6',
    fontFamily: 'proxima-nova',
    fontSize: '16px',
    margin: '6px 0 36px',
    textAlign: 'center',
  }),
  carrierAndPatientInfo: css({
    color: '#414b56',
    fontFamily: 'proxima-nova',
    fontSize: '24px',
    fontWeight: '600',
    margin: '0',
    textAlign: 'center',
  }),
  logoPadding: css({
    paddingTop: '3.5px 0',
  }),
  editButton: css({
    appearance: 'none',
    background: 'none',
    border: '0',
    color: '#0089bf',
    fontSize: '18px',
    fontWeight: '600',
    outline: 'none',
    cursor: 'pointer',
  }),
  foot: css({
    borderRadius: '0 0 5px 5px',
    display: 'flex',
    bottom: 0,
    position: 'absolute',
    width: '100%',
  }),
  boeingFoot: css({
    marginTop: '127px',
  }),
  errorMessageMargin: css({
    margin: '20px 0',
  }),
  validMessageContainer: css({
    alignItems: 'center',
    backgroundColor: '#e5f8eb',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    margin: '42px 0 18px',
    textAlign: 'center',
    width: '100%',
  }),
  validMessage: css({
    color: '#00bc37',
    fontFamily: 'proxima-nova',
    fontSize: '16px',
    margin: '0',
    padding: '24px 0',
    textAlign: 'center',
    width: '90%',
  }),
  validMessageIcon: css({
    boxSizing: 'border-box',
    display: 'flex',
  }),
  emphasis: css({
    fontWeight: '600',
  }),
  davisOther: css({
    color: '#414b56',
    fontFamily: 'proxima-nova',
    fontSize: '20px',
    margin: '30px 0',
    paddingLeft: '8px',
  }),
  formDivider: css({
    color: '#a1a4a6',
    margin: '0 0 50px',
    textAlign: 'center',
    width: '100%',
  }),
  davisEmployers: css({
    color: '#414b56',
    fontSize: '24px',
    fontWeight: '600',
  }),
  warningContainer: css({
    backgroundColor: '#fef8e5',
    border: '1px solid #f2e600',
    borderRadius: '6px',
    boxSizing: 'border-box',
    margin: '15px 0 33px',
    width: '100%',
  }),
  warningMessage: css({
    color: '#414b56',
    fontSize: '16px',
    margin: '0',
    padding: '24px 60px',
    textAlign: 'center',
  }),
  warningMessageEmphasis: css({
    color: '#414b56',
    fontSize: '16px',
    fontWeight: '600',
    margin: '0',
  }),
  spinningLoaderContainer: css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '904px',
    margin: '0px',
  }),
  check: css({
    color: '#43AC6A',
  }),
  redColor: css({
    color: '#d9534f',
  }),
  orBanner: css({
    color: '#d6003c',
    fontFamily: 'proxima-nova',
    fontSize: '40px',
    fontWeight: '900',
    margin: '0 auto',
    textAlign: 'center',
  }),
  andBanner: css({
    color: '#00a2e1',
    fontFamily: 'proxima-nova',
    fontSize: '40px',
    fontWeight: '900',
    margin: '0 auto',
    textAlign: 'center',
  }),
  orAndWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media screen and (max-width: 768px)': {
      flex: '0 0 100%',
    },
  }),
  benefitContainer: css({
    padding: '25px 0',
    '@media screen and (min-width: 1024px)': {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '2.6rem',
      paddingRight: '2.6rem',
      justifyContent: 'space-between',
    },
    '@media screen and (max-width: 768px)': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  }),
  smallBenefitChipsContainer: css({
    margin: '0 auto',
    '@media screen and (max-width: 768px)': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: '0 0 100%',
    },
  }),
};

// use Hooks to update benefits check loading state
// replace form submit button content with three circles
// use keyframes to animate circles to indicate loading state of benefits check
// set timeout to show loading state for 2.5 amount of seconds
// use Hooks to update loading state to false
const loadingAnimation = css.keyframes({
  '0%': { opacity: '0' },
  '20%': { opacity: '1' },
  '100%': { opacity: '0.1' },
});
export const circle = css({
  animationName: `${loadingAnimation}`,
  animationDuration: '1.5s',
  animationIterationCount: 'infinite',
  animationFillMode: 'both',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  borderRadius: '50%',
  display: 'inline-block',
  height: '12px',
  width: '12px',

  '&#second': {
    animationDelay: '.2s',
    marginLeft: '10px',
  },

  '&#third': {
    animationDelay: '.4s',
    marginLeft: '10px',
  },
});

// Spinning animation for loading screen
const spinningAnimation = css.keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});
export const spinner = css({
  animation: `${spinningAnimation} 1s infinite linear`,
  borderBottom: '2px solid rgba(0, 0, 0, .2)',
  borderLeft: '2px solid #414B56',
  borderRadius: '50%',
  borderRight: '2px solid rgba(0, 0, 0, .2)',
  borderTop: '2px solid rgba(0, 0, 0, .2)',
  height: '50px',
  margin: '0 auto',
  transform: 'translateZ(0)',
  width: '50px',
});
