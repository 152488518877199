import React from 'react';

/**
 * TODO(RETAILAPPS-127)
 * Currently Helios only understands the below carriers.
 * We have to maintain a mapping of Helios carriers to
 * POE carriers until FinDev can update their API to support
 * the carriers below.
 */
export enum CarrierKey {
  // compatible
  Davis = 'davis',
  United = 'united',
  EyeMed = 'eyemed',
  Guardian = 'guardian',
  CareFirst = 'carefirst',
  Superior = 'superior',

  // out of network
  Aetna = 'aetna',
  Anthem = 'anthem',
  Cigna = 'cigna',
  Humana = 'humana',
  Vsp = 'vsp',
  Other = 'other',
}

export enum Company {
  GeneralElectric = 'GE',
  Boeing = 'Boeing',
}

export enum BenefitSection {
  ContactFittings = 'benefit_contact_fittings',
  ContactLenses = 'benefit_contact_lenses',
  ContactLensesAndFittings = 'benefit_contact_lenses_and_fittings',
  Exams = 'benefit_exams',
  Frames = 'benefit_frames',
}

export enum InsuranceEligibilityType {
  Frame = 'frame',
  Exam = 'exam',
  Contacts = 'contacts',
}

// Note: this is the custom benefit type, not the one is returned from backend
export enum BenefitType {
  Frame = 'Frames',
  Lens = 'Lenses',
  Exam = 'Glasses Exam',
  ContactLenses = 'Contact Lenses',
  ContactLensFit = 'Contacts Exam',
  ContactLensesAndFit = 'Contact Lenses and Exam',
  DigitalReginalImaging = 'Digital Retinal Imaging',
  FramePackage = 'Frame Package',
}

export enum BenefitIconName {
  Frame = 'frame',
  FrameDisabled = 'frame-desabled',
  Lens = 'lens',
  LensDisabled = 'lens-disabled',
  Exam = 'exam',
  ExamDisabled = 'exam-disabled',
  ContactLenses = 'contact-lenses',
  ContactLensesDisabled = 'contact-lenses-disabled',
  ContactLensFit = 'contact-lens-fit',
  ContactLensFitDisabled = 'contact-lens-fit- disabled',
}

export interface Carrier {
  name: string;
  carrierKey: CarrierKey;
  id?: string;
  logo?: string;
  phoneNumber?: string;
  inNetwork?: boolean;
  primary?: boolean;
  featureFlagRequired?: string;
  featureFlagExcludes?: string;
  eligibilityForm?: React.ReactNode;
}

export interface Benefits {
  benefit_eligibility_id: number;
  [BenefitSection.ContactFittings]: Benefit[];
  [BenefitSection.ContactLenses]: Benefit[];
  [BenefitSection.ContactLensesAndFittings]: Benefit[];
  [BenefitSection.Exams]: Benefit[];
  [BenefitSection.Frames]: Benefit[];
  is_success: boolean;
  response_message: string;
}

export interface Benefit {
  age_max: number;
  age_min: number;
  age_range: string;
  amount_allowance: number;
  amount_allowance_display: string;
  amount_allowance_in_cents: number;
  amount_allowance_remaining: number;
  amount_allowance_remaining_display: string;
  amount_allowance_remaining_in_cents: number;
  amount_allowance_satisfied: number;
  amount_allowance_satisfied_display: string;
  amount_allowance_satisfied_in_cents: number;
  amount_blue_light_filter_copay: number;
  amount_blue_light_filter_copay_display: string;
  amount_blue_light_filter_copay_in_cents: number;
  amount_copay: number;
  amount_copay_display: string;
  amount_copay_in_cents: number;
  amount_photochromic_lens_copay: number;
  amount_photochromic_lens_copay_display: string;
  amount_photochromic_lens_copay_in_cents: number;
  amount_price_protection: number;
  amount_price_protection_display: string;
  amount_price_protection_in_cents: number;
  amount_ultra_high_index_copay: number;
  amount_ultra_high_index_copay_display: string;
  amount_ultra_high_index_copay_in_cents: number;
  benefit_available: boolean;
  benefit_available_on?: string;
  benefit_available_on_display?: string;
  benefit_category: string;
  benefit_desc: string;
  benefit_eligibility: string;
  benefit_frequency: string;
  benefit_key: string;
  benefit_name: string;
  benefit_type: string;
  customer_responsibility: string;
  has_retinal_imaging: boolean;
  has_separate_lens_benefits: boolean;
  insurance_eligibility_item_id: number;
  insurance_eligibility_type: InsuranceEligibilityType;
  is_copay_required: boolean;
  is_fully_covered: boolean;
  is_multi_unit_benefit: boolean;
  lens_benefit_available: boolean;
  lens_benefit_frequency: string;
  lens_dynamic_benefit: boolean;
  lens_fully_covered_anti_fatigue: boolean;
  lens_fully_covered_blue_light: boolean;
  lens_fully_covered_photo: boolean;
  lens_fully_covered_prism: boolean;
  lens_fully_covered_ultra_high_index: boolean;
  percent_coinsurance: number;
  percent_coinsurance_display: string;
  percent_discount: number;
  percent_discount_display: string;
  percent_overage: number;
  percent_overage_display: string;
  period_count: number;
  period_unit: string;
  plan_name: string;
  units_max: number;
  units_min: number;
  amount_benefit_display: string;
}

export interface BenefitToDisplay {
  benefitType: BenefitType;
  benefitAvailableOnDisplay: string | null;
  copayDisplay?: string;
  copayRequired: boolean;
  benefitDescription: string;
  hasBoeingBenefits?: boolean;
  benefitIcon: BenefitIconName;
  benefitAvailable: boolean;
}

export interface InsuranceBenefit {
  estimate_id: string;
  carrier_key: string;
  carrier_title: string;
  in_network: boolean;
  member_group: string;
  frame_benefits: Benefit[];
  exam_benefits: Benefit[];
  contacts_fitting_benefits: Benefit[];
  contact_lens_benefits: Benefit[];
  contact_lens_and_fitting_benefits: Benefit[];
  benefit_eligibility_id: string;
  has_only_frames_or_contact_lens: boolean;
}
