/* eslint-disable import/prefer-default-export */
import React from 'react';

import { styles } from './styles';
import { Benefits, Carrier, Company } from './types';
import { benefitsForDisplay } from './benefits-item-converter';
import { carrierLogoUrl } from './util';
import { BenefitContainer } from './components/benefit-container/benefit-container';

interface BenefitsSummaryProps {
  carrier: Carrier;
  company: Company;
  benefits: Benefits;
  patientName: string;
  loading: boolean;
  hasEstimate: boolean;
  applyToEstimate: () => void;
  resetSelectedCarrier: any;
  resetSelectedCompany: any;
  hasFramesOrContactLens?: boolean;
}

export function BenefitsSummary({
  carrier,
  company,
  benefits,
  applyToEstimate,
  loading,
  hasEstimate,
  resetSelectedCarrier,
  patientName,
  resetSelectedCompany,
  hasFramesOrContactLens,
}: BenefitsSummaryProps) {
  const {
    benefitsToDisplay,
    hasCaveat,
    anyAvailable,
  } = benefitsForDisplay({ benefits, carrier, company });

  const companyName = company && (company === Company.GeneralElectric ? 'General Electric' : company);
  const benefitProviderName = companyName || carrier.name;

  return (
    <div id="benefits-summary">
      <div {...styles.carrierPillContainer}>
        <div
          {...styles.carrierPill}
          className={(company) ? 'multi-pill' : undefined}
          onClick={() => resetSelectedCarrier({ company })}
        >
          <img
            {...styles.carrierLogo}
            {...styles.logoPadding}
            alt={carrier.name}
            src={carrierLogoUrl(carrier.logo)}
          />
          <button {...styles.editButton} type="button">Edit</button>
        </div>
        {(company) ? (
          <div
            {...styles.carrierPill}
            onClick={() => resetSelectedCompany({ benefitSummary: true })}
          >
            <img
              {...styles.carrierLogo}
              {...styles.logoPadding}
              {...(company === Company.Boeing ? styles.davisBoeingLogo : {})}
              alt={carrier.name}
              src={carrierLogoUrl(`${carrier.carrierKey}-${company.toLowerCase()}-logo.png`)}
            />
            <button {...styles.editButton} type="button">Edit</button>
          </div>
        ) : null}
      </div>
      <p {...styles.carrierContactInfo}>
        {`Questions? Customers can contact ${carrier.name} at ${carrier?.phoneNumber}.`}
      </p>
      <p {...styles.carrierAndPatientInfo}>
        {(patientName && `${patientName}'s Benefits w/ ${benefitProviderName}`)
        || `Benefits with ${benefitProviderName}`}
      </p>
      <BenefitContainer
        benefitsToDisplay={benefitsToDisplay}
        hasFramesOrContactLens={hasFramesOrContactLens}
      />
      <div {...styles.primaryButtonContainer}>
        <button
          {...styles.primaryButton}
          disabled={loading || !anyAvailable}
          onClick={applyToEstimate}
          data-browser-test-benefits-summary-main-button
        >
          {hasEstimate
            ? 'Apply insurance to order'
            : 'Start order with benefits'}
        </button>
      </div>
      {hasCaveat ? <p {...styles.benefitsExtraInfo}>* Must use this allowance first</p> : null}
    </div>
  );
}
