/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';

import { styles } from '../styles';

import AnimatedLoader from '../animated-loader';
import Error from '../error';
import { EligibilityFormProps } from './types';

function GuardianEligibilityForm({
  carrier,
  carrierLogoUrl,
  dismissError,
  loading,
  patientData,
  handleFormSubmit,
  handleSubmit,
  handleUpdate,
  errorMessage,
  resetSelectedCarrier,
}: EligibilityFormProps) {
  const [isBenefitCheckLoading, setBenefitCheckLoading] = useState(false);

  const updatePatientData = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdate(e.target.name, e.target.value);
  };

  const isLoading = loading || isBenefitCheckLoading;

  let fieldExtras = {
    ...styles.formField,
    disabled: isLoading,
  };

  if (errorMessage) {
    fieldExtras = { ...fieldExtras, ...styles.errorField };
  }

  const { first_name, last_name, subscriber_dob, _id_field } = patientData;

  return (
    <>
      <div {...styles.carrierPill} onClick={() => resetSelectedCarrier({})}>
        <img
          {...styles.carrierLogo}
          {...styles.logoPadding}
          alt={carrier.name}
          src={carrierLogoUrl(carrier.logo)}
        />
        <button {...styles.editButton} type="button">Edit</button>
      </div>
      <h3 {...styles.carrierName}>Enter member info to check benefits</h3>
      <Error dismissError={dismissError} message={errorMessage} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // if the form submit button has already been pressed to check
          // benefits, the button should be disabled, otherwise, make the
          // API call to get insurance benefits
          const patientDataForm = { first_name, last_name, subscriber_dob, _id_field };
          return isLoading ? null : (
            handleFormSubmit({
              handleSubmit,
              setBenefitCheckLoading,
              patientData: patientDataForm,
            })
          );
        }}
      >
        <div {...styles.formContainer}>
          <label htmlFor="first_name" {...styles.formFieldRow}>
            <span {...styles.formLabel}>First name</span>
            <input
              {...fieldExtras}
              onChange={updatePatientData}
              type="text"
              value={patientData.first_name}
              name="first_name"
            />
          </label>
          <label htmlFor="last_name" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Last name</span>
            <input
              {...fieldExtras}
              onChange={updatePatientData}
              type="text"
              value={patientData.last_name}
              name="last_name"
            />
          </label>
        </div>
        <div {...styles.formContainer}>
          <label htmlFor="subscriber_dob" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Date of birth</span>
            <input
              {...fieldExtras}
              onChange={updatePatientData}
              type="date"
              value={patientData.subscriber_dob}
              name="subscriber_dob"
            />
          </label>
          <label htmlFor="_id_field" {...styles.formFieldRow}>
            <span {...styles.formLabel}>Member ID</span>
            <input
              {...fieldExtras}
              onChange={updatePatientData}
              type="text"
              value={patientData._id_field}
              placeholder="000000000"
              name="_id_field"
            />
          </label>
        </div>
        <div {...styles.formButtonContainer}>
          <button
            {...styles.primaryButton}
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? <AnimatedLoader /> : 'Check for benefits'}
          </button>
        </div>
      </form>
    </>
  );
}

export default GuardianEligibilityForm;
