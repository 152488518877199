import React from 'react';
import { styles } from './styles';

interface ErrorProps {
  additionalMessage?: string;
  dismissError: () => void;
  message: string;
}

function Error({ additionalMessage = '', dismissError, message }: ErrorProps) {
  return message ? (
    <div
      {...styles.errorMessageContainer}
      onClick={dismissError}
      className={additionalMessage ? 'errorWrap' : undefined}
      data-browser-test-error-message
    >
      <p {...styles.errorMessage}>{message}</p>
      <span {...styles.errorIcon}>
        <img
          alt="form-submit-error"
          src="../static/red-x-icon.svg"
        />
      </span>
      {additionalMessage || null}
    </div>
  ) : null;
}

export default Error;
