/* eslint-disable import/prefer-default-export */
import React from 'react';
import { BenefitToDisplay, BenefitType } from '../../types';
import { styles } from '../../styles';
import BenefitItem from '../../benefit-item';
import { isGlassesOrContactLensBenefit, isContactLensBenefit, isBenefitForGlasses } from '../../util';

interface BenefitContainerProps {
  benefitsToDisplay: BenefitToDisplay[];
  hasFramesOrContactLens?: boolean;
}

const filterBenefits = (benefits: BenefitToDisplay[], typeOfBenefit: BenefitType) => {
  switch (typeOfBenefit) {
    case BenefitType.Lens:
      return benefits.filter((b) => isBenefitForGlasses(b.benefitType));
    case BenefitType.ContactLenses:
      return benefits.filter((b) => isContactLensBenefit(b.benefitType));
    case BenefitType.Exam:
      return benefits.filter((b) => !isGlassesOrContactLensBenefit(b.benefitType));
    default:
      return benefits.filter((b) => isGlassesOrContactLensBenefit(b.benefitType));
  }
};

export function BenefitContainer({ benefitsToDisplay, hasFramesOrContactLens }: BenefitContainerProps) {
  const glassesBenefits = filterBenefits(benefitsToDisplay, BenefitType.Lens);
  const contactLensBenefits = filterBenefits(benefitsToDisplay, BenefitType.ContactLenses);
  const glassesOrContactLensBenefits = filterBenefits(benefitsToDisplay, BenefitType.Frame);
  const examsBenefits = filterBenefits(benefitsToDisplay, BenefitType.Exam);

  const renderBenefitItems = (benefits: BenefitToDisplay[]) => (
    benefits.map((b, i) => (
      <BenefitItem
        benefit={b}
        key={`${i}-${b.benefitType}`}
      />
    ))
  );
  const renderBenefitChipsContainer = (benefits: BenefitToDisplay[]) => (
    <div {...styles.benefitChipsContainer}>
      {renderBenefitItems(benefits)}
    </div>
  );
  return (
    <>
      {hasFramesOrContactLens !== null ? (
        <div {...styles.benefitContainer}>
          {renderBenefitItems(glassesBenefits)}
          <div {...styles.orAndWrapper}>
            <p {...(hasFramesOrContactLens ? styles.orBanner : styles.andBanner)}>
              {hasFramesOrContactLens ? '\u00A0OR\u00A0' : 'AND'}
            </p>
          </div>
          <div {...styles.smallBenefitChipsContainer}>
            {renderBenefitItems(contactLensBenefits)}
          </div>
        </div>
      ) : (
        <>
          {renderBenefitChipsContainer(glassesOrContactLensBenefits)}
        </>
      )}
      {renderBenefitChipsContainer(examsBenefits)}
    </>
  );
}
