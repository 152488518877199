import { StyleAttribute } from 'glamor';
import React from 'react';
import { carrierLogoUrl } from '../../util';

interface Props {
  carrierLogo: string;
  carrierName: string;
  styles: { [key: string]: StyleAttribute }
}

export default function CarrierLogo(props: Props) {
  const { carrierLogo, carrierName, styles } = props;

  return (
    <div {...styles.carrierLogoContainer}>
      <img alt={carrierName} src={carrierLogoUrl(carrierLogo)} {...styles.carrierLogo} />
    </div>
  );
}
